<template>
<v-card elevation="3" style="width: 100%; min-height: 500px;">
  // Bientot disponible
</v-card>
</template>

<script>
export default {
  name: "ProjectImpactMilestonesTab"
}
</script>

<style scoped>

</style>
